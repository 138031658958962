import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "@components/layout";
import ThanksForSubmissionForm from "@modules/thanks-for-submission-form";

const ThanksForSubmission = () => {
  const {
    markdownRemark: {
      frontmatter: {
        backgroundColor,
        pageName,
        largeHeader,
        description,
        buttonLabel,
      },
    },
  } = useStaticQuery(
    graphql`
      {
        markdownRemark(fileAbsolutePath: { regex: "/thanks-for-submission/" }) {
          frontmatter {
            backgroundColor
            pageName
            largeHeader
            description
            buttonLabel
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Helmet>
        <title>{pageName}</title>
      </Helmet>
      <ThanksForSubmissionForm
        backgroundColor={backgroundColor}
        largeHeader={largeHeader}
        description={description}
        buttonLabel={buttonLabel}
      />
    </Layout>
  );
};

export default ThanksForSubmission;
